import React, { useEffect, useState } from 'react';
import { gapi } from 'gapi-script';

const GoogleCalendar = ({ onEventsFetched }) => {
  const [events, setEvents] = useState([]);

  const CLIENT_ID = '972328958765-lf9ni3rup13ghhu10fr6j4qvgn31qoms.apps.googleusercontent.com';
  const API_KEY = 'AIzaSyA7TQv8n5JgfO05b6UD8rtnjVgiVKLThXc';
  const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];
  const SCOPES = "https://www.googleapis.com/auth/calendar.readonly";


  const loadCalendarEvents = () => {
    gapi.client.calendar.events.list({
      calendarId: 'primary',
      timeMin: new Date().toISOString(),
      showDeleted: false,
      singleEvents: true,
      orderBy: 'startTime',
    }).then(response => {
      const events = response.result.items;
      setEvents(events);
      onEventsFetched(events);
    });
  };


  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      }).then(() => {
        gapi.auth2.getAuthInstance().signIn().then(() => {
          loadCalendarEvents();
        });
      });
    };

    gapi.load('client:auth2', initClient);
  }, [DISCOVERY_DOCS,loadCalendarEvents]);

  return (
    <div>
      <h2>Google Calendar Events</h2>
      {events.length > 0 ? (
        <ul>
          {events.map(event => (
            <li key={event.id}>{event.summary} ({new Date(event.start.dateTime).toLocaleString()})</li>
          ))}
        </ul>
      ) : (
        <p>No upcoming events.</p>
      )}
    </div>
  );
};

export default GoogleCalendar