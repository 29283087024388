import React, { useState, useEffect } from 'react';
import GoogleCalendar from './google-calendar';
import axios from 'axios';
import LoginPage from './login-page';

const App = () => {
  const [googleEvents, setGoogleEvents] = useState([]);
  const [eventbriteOrgs, setEventbriteOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [eventbriteEvents, setEventbriteEvents] = useState([]);
  const [calendlyEvents, setCalendlyEvents] = useState([]);
  const [showGooglePanel, setShowGooglePanel] = useState(false);

  // Fetch Eventbrite organizations
  const fetchEventbriteOrgs = async () => {
    const accessToken = localStorage.getItem('eventbrite_access_token');
    if (accessToken) {
      try {
        const response = await axios.get('https://www.eventbriteapi.com/v3/users/me/organizations/', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setEventbriteOrgs(response.data.organizations);
      } catch (error) {
        console.error('Error fetching Eventbrite organizations:', error);
      }
    }
  };

  // Fetch Eventbrite events for the selected organization
  const fetchEventbriteEvents = async (orgId) => {
    const accessToken = localStorage.getItem('eventbrite_access_token');
    if (accessToken && orgId) {
      try {
        const response = await axios.get(
          `https://www.eventbriteapi.com/v3/organizations/${orgId}/events/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setEventbriteEvents(response.data.events);
      } catch (error) {
        console.error('Error fetching Eventbrite events:', error);
      }
    }
  };

  // Handle Google Calendar events
  const handleGoogleEventsFetched = (fetchedEvents) => {
    setGoogleEvents(fetchedEvents);
  };

  // Handle organization selection
  const handleOrgChange = (e) => {
    const orgId = e.target.value;
    setSelectedOrg(orgId);
    fetchEventbriteEvents(orgId);
  };

  const fetchCalendlyUser = async () => {
    const accessToken = localStorage.getItem('calendly_access_token');
    if (accessToken) {
      try {
        const response = await axios.get('https://api.calendly.com/users/me', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        // Extract the user URI from the response
        return response.data.resource.uri;
      } catch (error) {
        console.error('Error fetching Calendly user:', error);
      }
    }
    return null;
  };

// Function to fetch Calendly events
const fetchCalendlyEvents = async () => {
    const accessToken = localStorage.getItem('calendly_access_token');
    if (accessToken) {
      try {
        // First, fetch the current user
        const userUri = await fetchCalendlyUser();
  
        if (userUri) {
          // Now fetch the events using the user's URI
          const response = await axios.get('https://api.calendly.com/scheduled_events', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              user: userUri, // Provide the user parameter
            },
          });
           setCalendlyEvents(response.data.collection); // This is the array of events
        }
      } catch (error) {
        console.error('Error fetching Calendly events:', error);
      }
    }
    return [];
  };

  const handleShowGoogle = () => {
    setShowGooglePanel(true);
  }
  
  const handleClearSaved = () => {
    localStorage.removeItem('eventbrite_access_token');
    localStorage.removeItem('calendly_access_token');
  }

  useEffect(() => {
    if (localStorage.getItem('eventbrite_access_token')) {
      fetchEventbriteOrgs(); // Fetch Eventbrite organizations once user is authenticated
    }

    if (localStorage.getItem('calendly_access_token')) {
      fetchCalendlyEvents(); // Fetch Calendly events once user is authenticated
    }
  }, []);

  return (
    <div>
      <h1>Event Sync Application</h1>
      <LoginPage />
      
      <button onClick={handleShowGoogle}>Login to Google</button>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {/* Google Calendar Section */}
        <div style={{ flex: 1 }}>
          <h2>Google Calendar Events</h2>
          {showGooglePanel == true ? ( <GoogleCalendar onEventsFetched={handleGoogleEventsFetched} />) : (
            <p>Please log in to Google to see calendar entries.</p>
          )}
         
          <ul>
            {googleEvents.map((event) => (
              <li key={event.id}>
                {event.summary} ({new Date(event.start.dateTime).toLocaleString()})
              </li>
            ))}
          </ul>
        </div>

        {/* Eventbrite Section */}
        <div style={{ flex: 1 }}>
          <h2>Eventbrite</h2>
          {eventbriteOrgs.length > 0 ? (
            <div>
              <label htmlFor="orgSelect">Select Organization:</label>
              <select id="orgSelect" value={selectedOrg} onChange={handleOrgChange}>
                <option value="">--Select an Organization--</option>
                {eventbriteOrgs.map((org) => (
                  <option key={org.id} value={org.id}>
                    {org.name}
                  </option>
                ))}
              </select>

              {/* Display Eventbrite Events after selecting an organization */}
              {eventbriteEvents.length > 0 && (
                <div>
                  <h3>Eventbrite Events for {eventbriteOrgs.find(org => org.id === selectedOrg)?.name}</h3>
                  <ul>
                    {eventbriteEvents.map((event) => (
                      <li key={event.id}>
                        {event.name.text} ({new Date(event.start.utc).toLocaleString()})
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <p>Please log in to Eventbrite to see organizations.</p>
          )}
        </div>

        {/* Calendly Section */}
        <div style={{ flex: 1 }}>
          <h2>Calendly Events</h2>
          {calendlyEvents.length > 0 ? (
            <ul>
              {calendlyEvents.map((event) => (
                <li key={event.uri}>
                  {event.name} ({new Date(event.start_time).toLocaleString()})
                </li>
              ))}
            </ul>
          ) : (
            <p>Please log in to Calendly to see your events.</p>
          )}
        </div>
      </div>
      <button onClick={handleClearSaved}>Clear Saved Credentials</button>

    </div>
    
  );
};

export default App;
