import React from 'react';

const LoginPage = () => {
  const handleEventbriteLogin = () => {
        //const client = 'TAAO2HTHJKVZDAHGFF';

    const client = '5VD3XLRYJ5MZ6IGKH5';

    const eventbriteAuthUrl = `https://www.eventbrite.com/oauth/authorize?response_type=token&client_id=${client}&redirect_uri=${encodeURIComponent(window.location.origin)}/eventbrite/callback`;
    window.location.href = eventbriteAuthUrl;
  };
  const handleCalendlyLogin = () => {
    //const client = 'dhslyKJnMv78qnTgZfS9JacBcAby94WCfnjvGavpuEI';

    const client = 'oCcK4x-I8CtrLMigzfdmKQX9JYLI1z9hl2qWAiwoL-o';

    const calendlyAuthUrl = `https://auth.calendly.com/oauth/authorize?client_id=${client}&response_type=code&redirect_uri=${encodeURIComponent(window.location.origin)}/calendly/callback`;
    window.location.href = calendlyAuthUrl;
  };


  return (
    <div>
      <button onClick={handleEventbriteLogin}>Login to Eventbrite</button>
      <button onClick={handleCalendlyLogin}>Login to Calendly</button>
    </div>
  );
};

export default LoginPage;
